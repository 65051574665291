const USERS_ROOT = `/users`;
const TARIF590_ROOT = `/tariff590`;

export const INNER_ROUTES = {
    USERS: {
        WELCOME: `${USERS_ROOT}/welcome`,
        RESET_PASSWORD: `${USERS_ROOT}/reset-password`,
        FORGOT_PASSWORD: `${USERS_ROOT}/forgot-password`,
        ACTIVATE_USER: `${USERS_ROOT}/activate-user`,
        SIGN_IN: `${USERS_ROOT}/signin`,
        SIGN_UP: `${USERS_ROOT}/signup`,
    },
    DASHBOARD: {
        DASHBOARD: 'dashboard/dashboard',
        FAVORITES: 'dashboard/favorites',
        APPOINTMENT: 'dashboard/appointment-list',
        INVOICINGLIST: 'dashboard/invoice-list',
        INVOICINGVIEW: 'dashboard/invoice-details',
        SETTINGS: 'dashboard/settings',
        FAQ: 'dashboard/faqPage',
        CONTACT: 'dashboard/contact',
    },
    TARIF590: {
        ADDRESSES: `${TARIF590_ROOT}/addresses`,
        INVOICE_HEADER: `${TARIF590_ROOT}/invoice-header`,        
        INVOICE_ITEMS: `${TARIF590_ROOT}/invoice-items`,
        INVOICE_PREVIEW: `${TARIF590_ROOT}/invoice-preview`,
        PROFILE: `${TARIF590_ROOT}/profile`,
        DOCUMENTS: `${TARIF590_ROOT}/documents`,
    }
};